import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Create a theme with default parameters
const tribalismTheme = createTheme({
  palette: {
    common: {
      white: '#fff'
    }
  },
  components: {
    MuiSelect: {
      variants: [
        {
          props: { variant: "standard" },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#fff !important",
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(tribalismTheme);
